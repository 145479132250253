import React, { useEffect, useState } from "react";
import HeaderHome from "../headers/headerHome";
import * as API from "../services/API";
import '../css/Prog.css';

function Programmation(){
	const [ProgrammationList, setProgrammationList] = useState([]);
	
	useEffect(() => {
		API.getAllProgrammationRequest(
			(response)=>{
				setProgrammationList(response.data.data);
			},
			()=>{
				console.log("err");
			}
			)
		},
		[]
		);

	return (
		<>
		<HeaderHome />
			<div className="concert-program">
				<h1 className="text-center m-5 text-light">Evènements à venir</h1>
				<div className="concert-list">
					{ProgrammationList.map(concert => (
					<div key={concert.id} className="concert-card">
						<h2>{concert.artist}</h2>
						<p>Date: <span>{concert.date}</span></p>
						<p>Lieu: <span>{concert.location}</span></p>
					</div>
					))}
				</div>
			</div>
		</>
	);
}


export default Programmation;