import React, { useContext,useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import HeaderHome from "../headers/headerHome";
import {UserContext} from "../userContext/userContext";
import { useForm } from "react-hook-form";
import * as API from "../services/API";
import Loader from "../layout/Loader";
import { FaEye, FaRegEyeSlash, FaTimes } from "react-icons/fa";


function Login(){
	const { userDispatch } = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const [viewPassword, setViewPassword] = useState(false);
	const [error, setError] = useState(false);
	const [show, setShow] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const history = useHistory();
	const onSubmit = () => {
		setLoading(true);
		API.LoginRequest(username,password,(res)=>{
			console.log(res);
			const user = res.data.user;
			userDispatch({
				type: "LOGIN",
				payload: user
			});
			setLoading(false);
			sessionStorage.setItem("isLogged", JSON.stringify(true));
			localStorage.setItem("firstName",JSON.stringify(user.first_name))
			localStorage.setItem("last_name",JSON.stringify(user.last_name))
			localStorage.setItem("role",JSON.stringify(user.role))
			localStorage.setItem("_id",JSON.stringify(user._id))
			sessionStorage.setItem("jwttoken", res.headers.jwttoken);
			if(user.role === 2){
				history.push("/admin/gestion");
			}else{
				history.push("/ca/home");
			}
		},()=>history.push("/login"))
	};
	const { t } = useTranslation("common");

	return (
		<>
		{loading ? (
					<Loader />
				) :
            <div className={"h-100"} style={{backgroundColor:"#D3D3D3"}}>
			    <HeaderHome />
				<div className={"d-flex justify-content-center h-100 w-100"}>
					<div className="d-flex justify-content-center h-50 w-25 m-5 border border-secondary rounded" style={{backgroundColor:"#7EC8E3"}}>
					<form onSubmit={onSubmit} className="justify-content-center m-5">
						{error ? <p className="error-msg">{t("login.__loginError__")}</p> : null}
						<div className="form-group">
							<label htmlFor="login">{t("common.__login__")}</label>
							<input
								type="text"
								className="form-control"
								id="login"
								aria-describedby="loginHelp"
								value={username}
								onChange={(e)=>setUsername(e.target.value)}
								name="login"
							/>
							{/* <small id="loginHelp" className="form-text text-muted">
								{errors.login && t("common.__required__")}
							</small> */}
						</div>
						<div className="form-group">
							<div className="input-password">
								<label htmlFor="password">{t("common.__password__")}</label>
								<input
									type={viewPassword ? "text" : "password"}
									className="form-control"
									id="password"
									value={password}
									onChange={(e)=>setPassword(e.target.value)}
									name="password"
									aria-describedby="passwordHelp"
								/>
								<button
									className="view-password"
									type="button"
									onClick={() => setViewPassword(prevViewPassword => !prevViewPassword)}>
									{viewPassword ? <FaRegEyeSlash /> : <FaEye />}
								</button>
								{/* <small id="emailHelp" className="form-text text-muted">
									{errors.password && t("common.__required__")}
								</small> */}
							</div>
						</div>
						<br />
						<Link to="/initpassword">
							<p className="form-text button-text">{t("login.__lostPassword__")}</p>
						</Link>
						<br />
						<button type="submit" className="btn btn-dark">
							{t("users.__loginBtn__")}
						</button>
						{/* <button type="button" onClick={()=>API.CreateUserRequest(()=>console.log("USER created"),()=>console.log("USER FAIL"))} className="btn btn-danger m-2">
							{t("users.__createBtn__")}
						</button> */}
					</form>

					</div>
				</div>
            </div>}
		</>
	);
}


export default Login;