import http from "./Interceptor";
import axios from "axios";
const BASEURL = "/api";
export const LoginRequest = (username,password, onSuccess, onResponse) =>{
	if (username && password) {
		http
			.post(`https://lsr47.fr/api/login/`,{username:username,password:password})
			.then(res => onSuccess(res))
			.catch(() => onResponse());
	}
};
export const CreateUserRequest = (onSuccess, onResponse) =>{
		http
			.post(`https://lsr47.fr/api/user/create`,{})
			.then(res => onSuccess(res))
			.catch(() => onResponse());
};



export const CreateActiviteRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.post(`https://lsr47.fr/api/admin/activite/create`,{data:data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const getActiviteRequest = (onSuccess, onResponse) =>{
	http
		.get(`https://lsr47.fr/api/activite/get`)
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};
export const subscribeActiviteRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");


	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
		http
		.post(`https://lsr47.fr/api/ca/activite/subscribe`,{data:data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
	}else{
		onResponse()
	}
	
};
export const unsubscribeActiviteRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");


	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
		http
		.post(`https://lsr47.fr/api/ca/activite/unsubscribe`,{data:data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
	}else{
		onResponse()
	}
	
};

export const getAllActiviteRequest = (onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.get(`https://lsr47.fr/api/admin/activite/get`)
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const getAllMembersRequest = (onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.get(`https://lsr47.fr/api/admin/user/getall`)
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const getAllMembersByRoleRequest = (onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.get(`https://lsr47.fr/api/ca/user/get`)
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const deleteMemberRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.post(`https://lsr47.fr/api/admin/user/remove`,{member_id:data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const createMemberRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.post(`https://lsr47.fr/api/admin/user/create`,{data: data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const updateMemberRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.post(`https://lsr47.fr/api/admin/user/update`,{data: data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};


export const deleteActiviteRequest = (data, onSuccess, onResponse) =>{
	console.log(data);
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.post(`https://lsr47.fr/api/admin/activite/remove`,{activite_id:data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const CreateProgRequest = (data, onSuccess, onResponse) =>{
	http
		.post(`https://lsr47.fr/api/admin/activite/create`,{data:data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const createPublicGalerieRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	let formData = new FormData();
	formData.append('name', data.name);
	formData.append('image', data.image);
	http
		.post(`https://lsr47.fr/api/admin/galerie/public/create`, formData, {headers: {
			'Content-Type': 'multipart/form-data', // Indiquer que le corps contient des données multipart
		}})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const createMemberGalerieRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");
	console.log(data);
	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	let formData = new FormData();
		formData.append('name', data.name);
		formData.append('image', data.image);
	http
		.post(`https://lsr47.fr/api/admin/galerie/create`,formData , {headers: {
			'Content-Type': 'multipart/form-data', // Indiquer que le corps contient des données multipart
		}})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};
export const deletePublicImageRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.post(`https://lsr47.fr/api/admin/galerie/public/delete`,{data:data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};
export const deleteMemberImageRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.post(`https://lsr47.fr/api/admin/galerie/delete`,{data:data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};
export const getMembersGalerieRequest = (onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.get(`https://lsr47.fr/api/ca/galerie/get`)
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const getAllGalerieRequest = (onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.get(`https://lsr47.fr/api/ca/galerie/get`)
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};


export const getPublicGalerieRequest = (onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.get(`https://lsr47.fr/api/galerie/get`)
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};

export const getProgrammationRequest = (onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.get(`https://lsr47.fr/api/admin/prog/get`)
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};
export const getAllProgrammationRequest = (onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.get(`https://lsr47.fr/api/prog/get`)
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};
export const createProgrammationRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.post(`https://lsr47.fr/api/admin/prog/create`,{data: data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};
export const removeProgrammationRequest = (data, onSuccess, onResponse) =>{
	var sessionJWT = sessionStorage.getItem("jwttoken");

	if (sessionJWT) {
		axios.defaults.headers.common["jwtToken"] = sessionJWT;
	}
	http
		.post(`https://lsr47.fr/api/admin/prog/remove`,{data: data})
		.then(res => onSuccess(res))
		.catch(() => onResponse());
};




