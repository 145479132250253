import React, { useEffect, useState } from "react";
import HeaderHomeAdmin from "../headers/headerHomeAdmin";
import * as API from "../services/API";
import Icon from "../util/Icon";
import { Modal, Button } from "react-bootstrap";
import {
	FaToggleOn,
	FaToggleOff,
} from "react-icons/fa";

const MyModal = ({ onClose,onAdd }) => {
	const [name, setName] = useState("");
	const [image, setImage] = useState(null);
	const onUploadFiles = (e) => {
		console.log("e.target.files");
		console.log(e.target.files);
		if (e.target.files && e.target.files.length > 0) {
			console.log(e.target.files[0]);
			setImage(e.target.files[0]);
		}
	}

	// const [description, setDescription] = useState("");
	return (
	<Modal
        show={true}
        onHide={onClose}
        size={"lg"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Importer une image
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
			<label htmlFor="name">Nom</label>
			<input
				type={"text" }
				className="form-control m-2"
				id="name"
				onChange={(e)=>setName(e.target.value)}
				name="name"
			/>
			<input
				type="file"
				id="image"
				name="image"
				accept="image/*,application/pdf"
				onChange={e => onUploadFiles(e)}
			/>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Annuler
          </Button>
		  <Button variant="primary" onClick={()=>{console.log(image);onAdd({name:name,image:image})}}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    );
}	

function GalerieAdmin(){
	const [modalOpen, setModalOpen] = useState(null);
	const [GalerieList, setGalerieList] = useState([]);
	const [GalerieListPublic, setGalerieListPublic] = useState([]);
	const [isGalerieAdmin,setIsGalerieAdmin] = useState(false);
	useEffect(() => {
		API.getMembersGalerieRequest(
			(response)=>{
				setGalerieList(response.data.data);
				console.log(response.data.data)
				console.log(GalerieList)
			},
			()=>{
				console.log("err");
			}
			);
		API.getPublicGalerieRequest(
			(response)=>{
				setGalerieListPublic(response.data.data);
				console.log(response.data.data)
				console.log(GalerieList)
			},
			()=>{
				console.log("err");
			}
			)
		},
		[]
		);
	const addPublicGalerie = (data) => {API.createMemberGalerieRequest(data,()=>{API.getPublicGalerieRequest((response)=>{setGalerieList(response.data);closeModal();},(data)=>console.error(data))},(data)=>{console.error(data)})};
    const addMemberGalerie = (data) => {API.createPublicGalerieRequest(data,()=>{API.getMembersGalerieRequest((response)=>{setGalerieList(response.data);closeModal();},(data)=>console.error(data))},(data)=>{console.error(data)})};
	const deleteImagePublic = (data) => {API.deletePublicImageRequest({name:data},()=>{setModalOpen(null);API.getAllGalerieRequest((response)=>{setGalerieList(response.data);},(data)=>console.error(data))},(data)=>{console.error(data)})};
	const deleteImageMember = (data) => {API.deleteMemberImageRequest({name:data},()=>{setModalOpen(null);API.getAllGalerieRequest((response)=>{setGalerieList(response.data);},(data)=>console.error(data))},(data)=>{console.error(data)})};
	const openAddModal = () => setModalOpen(<MyModal onClose={closeModal} onAdd={!isGalerieAdmin?addMemberGalerie:addPublicGalerie}/>);
	let toggleClick = () =>{
		setIsGalerieAdmin(!isGalerieAdmin)
	}
	const closeModal = () => setModalOpen(null);

		// let action = (a)=>(<>
		// <Icon  size="xl"
		// 	onClick={(e) =>{
		// 		API.deleteImageRequest(a._id,()=>{API.createMemberGalerieRequest(
		// 			(response)=>{
		// 				setGalerieList(response.data);
		// 			},
		// 			()=>{
		// 				console.log("err");
		// 			}
		// 			);
		// 			API.getPublicGalerieRequest(
		// 				(response)=>{
		// 					setGalerieListPublic(response.data.data);
		// 					console.log(response.data.data)
		// 					console.log(GalerieList)
		// 				},
		// 				()=>{
		// 					console.log("err");
		// 				}
		// 				)
					
		// 		},()=>{});
		// 	}
		// 	}
		// 	 icon="trash" clickable  />
		// </>);
	return (
		<>
            <div className="h-100 bg-light">
			    <HeaderHomeAdmin />
				
				{isGalerieAdmin?<h1 className="text-center m-5 text-dark">Galerie des membres :</h1>:<h1 className="text-center m-5 text-dark">Galerie des invités :</h1>}
				<div className="d-flex justify-content-center text-dark">Activer/Désactiver la galerie membres : {isGalerieAdmin?<FaToggleOn size={50} className="mx-5" onClick={() => toggleClick()} />:<FaToggleOff size={50} className="mx-5"  onClick={() => toggleClick()} />}</div>
				<button className="m-5 btn btn-dark border" onClick={openAddModal}>Ajouter une image</button>
				<div className="container">
				<div className="row">
					{isGalerieAdmin?GalerieList.map((image, index) => (
							<div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
								 <button 
									className="btn btn-danger btn-sm m-2" 
									onClick={() => deleteImageMember(image.fileName)} 
									style={{ zIndex: 1 }}
								>
									✕
								</button>
								<img
									src={"data:image/png;base64," + image.base64Image}
									alt={`Galerie Image ${index}`}
									className="img-fluid rounded shadow"
									style={{ objectFit: "cover", height: "200px", width: "100%" }}
								/>
							</div>
						)):GalerieListPublic.map((image, index) => (
							<div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
								 <button 
									className="btn btn-danger btn-sm m-2" 
									onClick={() => deleteImagePublic(image.fileName)} 
									style={{ zIndex: 1 }}
								>
									✕
								</button>
								<img
									src={"data:image/png;base64," + image.base64Image}
									alt={`Galerie Image ${index}`}
									className="img-fluid rounded shadow"
									style={{ objectFit: "cover", height: "200px", width: "100%" }}
								/>
							</div>
						))}
					</div>
				</div>
				{modalOpen}
            </div>
		</>
	);
}


export default GalerieAdmin;